import * as dompurify from 'dompurify'
import 'prismjs/themes/prism.css'
import Prism from 'prismjs'
import 'prismjs/components/prism-markdown.js'
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight'
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css'

export const toastEditor = {
    props: {
        fetchingFile: { type: Boolean, defualt: true },
        editorText: { type: String, default: '' }
    },
    data() {
        return {
            defaultOptions: {
                plugins: [[codeSyntaxHighlight, { highlighter: Prism }]],
                customHTMLSanitizer: html => {
                    return (
                        dompurify.sanitize(html, {
                            ADD_TAGS: ['iframe'],
                            ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target']
                        }) || ''
                    )
                },
                customHTMLRenderer: {
                    htmlBlock: {
                        iframe(node) {
                            return [
                                { type: 'openTag', tagName: 'iframe', outerNewLine: true, attributes: node.attrs },
                                { type: 'html', content: node.childrenHTML },
                                { type: 'closeTag', tagName: 'iframe', outerNewLine: true }
                            ]
                        }
                    },
                    htmlInline: {
                        big(node, { entering }) {
                            return entering ? { type: 'openTag', tagName: 'big', attributes: node.attrs } : { type: 'closeTag', tagName: 'big' }
                        }
                    }
                }
            },
            editorLoaded: false
        }
    },
    methods: {
        loaded(evt) {
            this.editorLoaded = true
        }
    },
    computed: {
        allLoaded() {
            return !this.fetchingFile && this.editorLoaded
        }
    },
    watch: {
        allLoaded(loaded) {
            if (loaded) {
                if (this.editorText) {
                    this.$refs.toastuiEditor.invoke('setMarkdown', this.editorText)
                }
                if (this.placeholderText) {
                    this.$refs.toastuiEditor.invoke('setPlaceholder', this.placeholderText)
                }
            }
        }
    }
}
